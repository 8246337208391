/** @format */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Manrope';
  color: #070725;
  font-size: 14px;
  max-height: 100vh;
  min-height: 100vh;
  overflow-y: hidden !important;
}

.wrapper {
  width: 100%;
  display: flex;
}
.progress-blocks {
  background-color: #f5f5f5;
  width: 100%;
}
.progress-block {
  background-color: #fff;
  padding: 20px;
  font-size: 16px;
}
.info-map-switcher {
  display: flex;
  border-radius: 10px;
  border: 1px solid #e2e2e2;
  width: 100%;
  margin: 10px 0 20px 0;
}
.info-map-switcher-var {
  width: 50%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #ebebeb;
}
.info-map-switcher-var:first-child {
  border-radius: 10px 0 0 10px;
}
.info-map-switcher-var:last-child {
  border-radius: 0 10px 10px 0;
}
.info-map-switcher-var img {
  margin-right: 5px;
}
.info-map-switcher-var.active {
  background-color: #fff;
}
.progress-block-top {
  display: flex;
  padding-bottom: 20px;
  /* height: calc(var(--tg-viewport-stable-height) - 210px); */
  width: 100vw !important;
  flex: 1 !important;
}
.progress-block-info {
  font-size: 13px;
  padding: 0 2px;
  /* height: calc(var(--tg-viewport-stable-height) - 210px); */
  overflow: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 !important;
}
.progress-block-info-lineblock {
  padding: 5px 0;
}
.progress-block-info-line {
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.pb-notes {
  width: 100%;
  padding: 20px;
  background-color: #0101010a;
  border-radius: 8px;
}
.pb-notes-title {
  font-size: 14px;
}
.pb-info-line-value {
  font-weight: bold;
  margin-left: 10px;
}
.pb-item {
  width: 46%;
}
.pb-item:first-child {
  margin-right: 20px;
}
.pb-item-title,
.pb-item-info {
  font-size: 12px;
}
.pb-item-title {
  margin-bottom: 10px;
  color: #0707257a;
}
.pb-item-info {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pb-item-img {
  margin-right: 10px;
}
.pb-item-img img {
  width: 32px;
}

.color-status {
  border-radius: 10px;
  margin-left: 5px;
  padding: 5px 9px;
  font-size: 14px;
  font-weight: bold;
}
.color-status.busy {
  background-color: #d9b7a24d;
  color: #a67151;
}
.color-status.superbusy {
  background-color: #a02c3933;
  color: #a02c39;
}
.submit-price-block-inputs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}
.submit-price-block input {
  width: 40%;
  height: 48px;
  border-radius: 8px;
  border: 1px solid #01010114;
  padding-left: 10px;
}
.progress-block-map .infobox {
  position: absolute;
  top: 34px;
  left: 45px;
  width: 166px;
  height: 144px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 10px;
  padding: 14px;
  font-size: 14px;
}
.progress-block-map .infobox .info-line {
  display: flex;
  margin-top: 9px;
}
.progress-block-map .infobox .info-line .info-label {
  color: #8e8e8e;
  margin-right: 5px;
}
.progress-block-map .infobox .info-line .info-value {
  font-weight: bold;
}
.pb-item-info {
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 14px;
}
.pb-item-title {
  margin-bottom: 10px;
  color: #0707257a;
  font-size: 14px;
}
.pb-item-img {
  margin-right: 10px;
}
.pb-item-img img {
  width: 32px;
}
/* REMOVE */
.progress-block-map img {
  width: 100%;
  /* height: calc(var(--tg-viewport-stable-height) - 200px); */
}

input[type='text'] {
  font-size: 18px;
}

.view-map {
  margin: 0;
  width: 100%;
  z-index: 1;
  flex: 1;
  flex-basis: 1;
  /*min-height: 300px;*/
  /*height: 300px;*/
}

#bid-button {
  background-color: rgb(51, 144, 236); /* Green */
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 8px;
  font-size: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.App {
  width: 100vw;
  height: 100vh;
  overflow: hidden !important;
  display: flex;
  flex-direction: column;
}

.bar {
  height: 8px;
  width: 50px;
  background-color: black;
  margin: 0 auto;
  border-radius: 10px;
  cursor: move;
}

.bar-zone:active {
  opacity: 0.5;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;

  display: flex !important;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-button-next {
  width: 20px !important;
  height: 20px !important;
}

:root {
  --swiper-navigation-size: 22px !important;
  --swiper-theme-color: #229ed9 !important;
}

.nav-icon {
  transform: scale(0.25);
  object-fit: contain;
  transform-origin: right bottom !important;
  box-shadow: 20px 4px 80px rgba(0, 0, 0, 0.2);
  position: absolute;
  z-index: 999999;
  right: -3px;
  top: calc(50% - 100px);
}

.swiper-slide img {
  object-fit: contain !important;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

:root {
  --toastify-color-progress-success: rgb(51, 144, 236) !important;
  --toastify-icon-color-success: rgb(51, 144, 236) !important;
}

.zoom {
  animation: zoom 2s infinite;
  background-color: #55bf3b;
  opacity: 0.5;
  border-radius: 50%;
  position: absolute;
}

.react-datepicker__month-container {
  width: calc(100vw - 32px - 87px);
}

.leaflet-popup-content {
  margin: 10px 21px 10px 10px !important;
}
